<template>
  <FormModal
    title="Quel type de pompe à chaleur souhaitez-vous?"
    subtitle="Voici les options qui correspondent à vos besoins"
    show-back-btn
  >
    <template #body>
      <TypeOfHeatPumpFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import TypeOfHeatPumpFormStep from '~/components/form/steps/typeOfHeatPump/TypeOfHeatPumpFormStep'

export default {
  components: {
    TypeOfHeatPumpFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Type de pompe à chaleur',
      headDescription:
        "Indiquez quel type d'installation de pompe à chaleur vous recherchez.",
      path: '/quotes-requests/type-pompe-a-chaleur',
      progressValue: 75,
      checkoutStep: 5,
    }
  },
}
</script>
